<template>
  <div class="ol_out" :class="orderArr.length ? '':'bg_white'">
    <nav class="ol_tab ali_c j_s d_f">
      <div class="ol_tab_item" v-for="(item,idx) in navArr" :key="idx" :class="idx === acNav ? 'ac' : ''" @click="changeState(item,idx)">
        <span class="p_r d_i">{{item.name}}</span>
      </div>
    </nav>
    <section class="ol_card_out">
      <div v-if="orderArr.length || refreshing || isLoading">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list :immediate-check="false" v-model="isLoading" :finished="finished" finished-text="没有更多了" @load="onLoadBottom">
            <div class="olc_item_out" v-for="(item,index) in orderArr" :key="index">
              <div class="head d_f ali_c">
                <img src="../imgs/order_icon.png" alt="" class="icon">
                <span>{{item.orderDetails[0].commodity.name}}{{item.orderDetails[0].commodity.orderId}}</span>
                <!-- closeType订单取消类型 1.用户 2 运营人员 -->
                <template v-if="item.order.orderStatus == 2 && item.order.closeType == 2">
                  <span class="flex_1 t_r">已取消</span>
                </template>
                <template v-else>
                  <template v-if="item.orderRefund == undefined || item.orderRefund == null">
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 0">未支付</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 1">待支付</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 2">已取消</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 2 && item.order.closeType == 'DB'">已退款</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 3">支付失败</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 4">待发货</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 5">待收货</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 6">发货失败</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 7">已完成</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 8">订单关闭</span>
                  </template>
                  <template v-else>
                    <span class="flex_1 t_r green" v-if="item.orderRefund.status == 1">退款审核中...</span>
                    <span class="flex_1 t_r green" v-if="item.orderRefund.status == 2">退票中...</span>
                    <span class="flex_1 t_r green" v-if="item.orderRefund.status == 3">退票失败</span>
                    <span class="flex_1 t_r green" v-if="item.orderRefund.status == 4">退款中</span>
                    <span class="flex_1 t_r green" v-if="item.orderRefund.status == 5">退款失败</span>
                    <span class="flex_1 t_r green" v-if="item.orderRefund.status == 6">退款成功</span>
                    <span class="flex_1 t_r green" v-if="item.orderRefund.status == 7">财务审核中</span>
                    <span class="flex_1 t_r green" v-if="item.orderRefund.status == 8">退款失败</span>
                  </template>
                </template>
              </div>
              <div class="olc_content d_f" @click="goToDetail(item)">
                <van-image fit="cover" :src="item.orderDetails[0].commodity.cover" />
                <div class="olcc_right flex_1">
                  <div class="name">{{item.orderDetails[0].commodity.name}}</div>
                  <div class="price">数量x{{item.orderDetails[0].orderDetail.quantity}}</div>
                  <div class="price d_f ali_b">
                    <span>总价</span>
                    <span class="red">￥{{item.order.totalSellPrice ? item.order.totalSellPrice/100 : 0}}</span>
                    <van-tag class="tag"  v-if="item.order.discountAmount" round plain color="#FF3E6E" text-color="#FF3E6E">消费劵 抵扣{{item.order.discountAmount?item.order.discountAmount/100:0}}</van-tag>
                  </div>
                </div>
              </div>
              <div class="jfje" v-show="item.order.totalDiscountAmount > 0">
                <span>总价 ￥{{item.order.totalListedPrice / 100}}</span>
                <span>实付 ￥{{item.order.totalSellPrice / 100}}</span>
              </div>
              <div class="olc_btn_out t_r">
                <div class="cur_p d_i t_a olc_btn" @click.stop="cancelOrder(item)" v-if="item.order.orderStatus == 1 || item.order.orderStatus == 0">取消订单</div>
                <div class="cur_p d_i t_a olc_btn ac" @click.stop="gotoPay(item)" v-if="item.order.orderStatus == 1 || item.order.orderStatus == 0">立即付款</div>
                <div class="cur_p d_i t_a olc_btn ac" @click.stop="goEvaluate(item)" v-if="item.order.orderStatus == 7">去评价</div>
                <template v-if="item.orderRefund != undefined && item.orderRefund != null">
                <div class="cur_p d_i t_a olc_btn" @click.stop="deleteOrder(item)" v-if="item.orderRefund.status == 6 || item.order.orderStatus == 3">删除订单</div>
                <div class="cur_p d_i t_a olc_btn" @click.stop="recallOrder(item)" v-if="item.orderRefund.status == 1">撤销申请</div>
                </template>
                <div class="cur_p d_i t_a olc_btn refund_btn" @click.stop="refundPay(item)"  v-if="item.isRe">申请退款</div>
                
                <div class="cur_p d_i t_a olc_btn" v-if="item.order.orderStatus == 4"><a href="tel:4008196333">联系商家</a></div>
                <div class="cur_p d_i t_a olc_btn" @click.stop="goToDetail(item)" v-if="item.order.orderStatus == 6">查看详情</div>
                <div class="cur_p d_i t_a olc_btn" @click.stop="reBuy(item)" v-if="item.order.orderStatus == 2 ||item.order.orderStatus == 8 || item.order.orderStatus == 7">再次购买</div>

              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <!--无订单数据-->
      <div v-else>
        <div class="d_f ali_c j_c">
          <div>
            <div class="no_order_out t_a">
              <img src="../imgs/no_order.png" alt="">
              <div class="title">你还没有订单哦！</div>
              <div class="sub">“我没有离开家，只是把家带回了远方~”</div>
              <div class="home_btn cur_p t_a d_i" @click="$router.push('/')">回家去看看吧~</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--    &lt;!&ndash; 客服咨询 &ndash;&gt;
    <van-popup v-model:show="showTel" closeable round >
      <div class="telPop">
        <span class="kefu">申请退款</span>
        <p>如需申请退款，可拨打如下客服号码</p>
        <a href="tel:4008196333" rel="external nofollow" >4008196333</a>
      </div>
    </van-popup>-->

    <!--退款原因-->
    <refundReasonRetail v-if="isShowRR" @close="refundSuccess" :orderNumber="rOrderNumber" hotelPhone="4008196333"></refundReasonRetail>
  </div>
</template>

<script src='./retailList.js'></script>
<style lang="scss" scoped src='./retailList.scss'></style>