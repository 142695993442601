import apis from '../utils/apis'
import { Dialog, Toast, Image, CountDown, DropdownMenu, DropdownItem, PullRefresh, List, Overlay, Field, Icon, Rate, Popup, Tag, } from 'vant';

import refundReasonRetail from "./refundReasonRetail.vue";
export default {
  name: "orderList",
  components: {
    refundReasonRetail,
    'van-dropdown-menu': DropdownMenu,
    'van-dropdown-item': DropdownItem,
    'van-pull-refresh': PullRefresh,
    'van-list': List,
    'van-overlay': Overlay,
    'van-icon': Icon,
    'van-rate': Rate,
    'van-field': Field,
    'van-image': Image,
    'van-count-down': CountDown,
    "van-popup": Popup,
    'van-tag': Tag,
  },
  data () {
    return {
      navArr: [
        { name: '全部订单', state: [] },
        { name: '待付款', state: [0,1] },
        { name: '待收货', state: [4] },
        { name: '退款', state: [4,5,7] }],
      orderArr: [],
      acNav: sessionStorage.getItem('tcTab') ? Number(sessionStorage.getItem('tcTab')) : 0,
      isLoading: false,
      isShowRR: false,
      finished: false,
      refreshing: false, //是否刷新
      isPayDetail: false, //详情弹窗
      showTel: false, //详情弹窗
      params: {
        data:{
          orderType: '6', //订单类型零售 
          includeStatuses: [], //订单状态
        },
        token: this.$global.token,
        pageIndex: 1, //当前页码
        pageSize: 10, //每页显示记录数
        sortOrder: "desc", //每页显示记录数
      },
      rOrderNumber: '',
      commentOrderId: '',
    }
  },
  created () {
    if (this.$global.token) {
      this.params.token = this.$global.token;
      this.getList()
    }
  },
  methods: {

    //撤回申请
    recallOrder (row) {
      let _t = this;
      Dialog.confirm({
        cancelButtonText: '我再想想',
        message: '确定撤销申请吗？',
      })
        .then(() => {
          _t.$post({
            url: apis.revokeRefundApply,
            params: {
              token: _t.$global.token,
              orderNumber: row.order.orderNumber,
            }
          }).then(res => {
            Toast('操作成功');
            _t.reloadList();
          }).catch(err => {
            Toast(err.message);
          })
        })
        .catch(() => {
          // on cancel
        });
    },
    // 申请退款
    refundPay (row) {
      // Dialog.confirm({
      //   cancelButtonText: '我再想想',
      //   message: '确定申请退款吗？',
      // })
      //   .then(() => {
      //     this.isShowRR = true;
      //     this.rOrderNumber = row.order.orderNumber;
      //   })
      //   .catch(() => {
      //   });
      let me = this;
      Dialog.confirm({
        cancelButtonText: '我再想想',
        message: '确定申请退款吗？',
      }).then(() => {
        me.$post({
          url: apis.refundApplyRetail,
          params: {
            orderId: row.order.orderId,
            token: me.$global.token,
          },
        }).then(res => {
          Toast('申请退款成功');
          me.reloadList();
        });
      })
        .catch(() => { });
    },
    refundSuccess () {
      let _t = this;
      _t.isShowRR = false;
      this.reloadList();
    },
    //切换tab
    changeState (row, index) {
      let _t = this;
      _t.acNav = index;
      sessionStorage.setItem('tcTab', index);
      _t.finished = false;
      this.reloadList();
    },
    reloadList () {
      let _t = this;
      _t.params.pageIndex = 1;
      _t.orderArr = [];
      _t.getList()
    },
    // 去支付
    gotoPay (row) {
      this.$router.push({
        path: '/goodsPay',
        query: {
          orderId: row.order.orderId,
        }
      });
    },
    //删除订单
    deleteOrder (row) {
      let _t = this;
      Dialog.confirm({
        cancelButtonText: '我再想想',
        message: '确定删除订单吗？',
      })
        .then(() => {
          _t.$post({
            url: apis.deleteOrder,
            params: {
              token: _t.$global.token,
              orderId: row.order.orderId,
            }
          }).then(res => {
            Toast('操作成功');
            _t.reloadList();
          })
        })
        .catch(() => {
          // on cancel
        });

    },
    getList () {
      let _t = this;
      _t.isLoading = true;
      _t.params.data.includeStatuses = _t.navArr[_t.acNav].state;
      this.$post({
        url: apis.pageMyOrderRetail,
        params: { ..._t.params }
      }).then(res => {
        this.isLoading = false;
        if (_t.refreshing) {
          _t.refreshing = false;
        }
        if (res.data.count) {
          res.data.data.map(item=>{
            item.isRe = false // 是否可以退款
            if(item.order.orderStatus == 4 || item.order.orderStatus == 5 || item.order.orderStatus == 7){
              if(item.orderRefund == undefined || item.orderRefund == null){
                item.isRe = true
              }
            }
          })
          _t.orderArr = _t.orderArr.concat(res.data.data);
          _t.orderArr.forEach((ele, index) => {
            _t.orderArr[index] = Object.assign({}, ele, { color: null })
          });
        }
        let list = res.data.data;
        if (!list || list.length < _t.params.pageSize) {
          _t.finished = true;
        }
      });
    },
    //查看订单详情
    goToDetail (row) {
      console.log(row)
      this.$router.push({
        path: '/retailOrderDetail',
        query: {
          orderId: row.order.orderId
        }
      })
    },
    /**再次购买 */
    reBuy (row) {
      this.$router.push({
        path: '/goodsInfo/',
        query: {
          commodityId: row.orderDetails[0].commodity.commodityId,
        },
      });
    },
    //取消订单
    cancelOrder (row) {
      let me = this;
      Dialog.confirm({
        title: '提示',
        message: '确认取消订单？',
      }).then(() => {
        // on confirm
        me.$get({
          url: apis.cancelOrder,
          params: {
            orderId: row.order.orderId,
            token: me.$global.token,
          }
        }).then(res => {
          Toast('取消成功');
          me.reloadList();
        });
      })
        .catch(() => {
          // on cancel
        });

    },
    //去评价
    goEvaluate(row){
      this.$router.push({
        path: '/skiEvaluate/',
        query: {
          orderId: row.order.orderId,
        },
      });
    },
    //上啦加载
    onLoadBottom () {
      let me = this;
      let page = me.params.pageIndex;
      page = page ? page + 1 : 1;
      me.params.pageIndex = page;
      me.getList();
    },
    //下拉刷新
    onRefresh () {
      let me = this;
      me.orderArr = [];
      me.finished = false;
      me.params.pageIndex = 1;
      me.getList();
    },
  }
}