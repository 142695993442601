import { Dialog,Toast,Loading, Image, CountDown, DropdownMenu, DropdownItem,PullRefresh, List, Overlay, Field, Icon, Rate } from 'vant';
import hotelList from '../../hotel/order/orderList'
import comboList from '../../combo/comboListOrder'
import retailList from '../../ski/retailList/retailList.vue'
export default {
  name: "orderList",
  components:{
    hotelList,
    comboList,
    retailList, //2022 冰雪节
    'van-dropdown-menu': DropdownMenu,
    'van-dropdown-item': DropdownItem,
    'van-pull-refresh':PullRefresh,
    'van-list': List,
    'van-overlay': Overlay,
    'van-icon': Icon,
    'van-rate': Rate,
    'van-field': Field,
    'van-image': Image,
    'van-count-down': CountDown,
    'van-loading': Loading,
  },
  data() {
    return {
      api: {
        orderList: 'stlsOrder/queryOrderList',
        cancelOrder:'/stlsOrder/cancel',
        refund:'/stlsOrder/refundApply',
        insertUserCriticism:'/userCriticism/insertUserCriticism',
        refundSnow:'/stlsOrder/platformRefundApply',
      },
      chooseOrderType:1,
      orderType:[
        { text: '智游乐山景区票务', value: 0 },
        { text: '智游乐山酒店', value: 1 },
        // { text: '乐山礼物', value: 2 },
        // { text: '智游乐山套餐', value: 3 },
        // { text: '扫码购订单', value: 4 },
        { text: '零售订单', value: 5 },//2022 冰雪节
      ],
      tabs: [
        {state:-1,name:'全部'},
        {state:1,name:'待付款'},
        {state:2,name:'待使用'},
        {state:3,name:'待评价'},
        {state:4,name:'退款/售后'},
      ],
      acIdx: sessionStorage.getItem('ticketListTab') ? Number(sessionStorage.getItem('ticketListTab')) : 0,
      isLoading: true,
      finished: false,
      orderlist: [],
      showInp: false, //评论弹框
      content: '', //评论
      params: { //门票搜索条件
        token: this.$global.token,
        orderType: '1',
        orderTypes: '1,4',
        // orderNumber: "stls061120354509503",
        pageIndex: 1, //当前页码
        pageSize: 10, //每页显示记录数
        orderStatus: '-1', //订单状态
        sortOrder: "desc", //每页显示记录数
      },
      refreshing: false, //是否刷新
      stateInfo:{
        token:this.$global.token,
        data:{
          bizType:3,
          bizId:null,
          parentId:0,
          content:'',
          starScore:5,
        }
      },
      stateInfoCId:'',
      chooseText:'景点门票',
      showCPop:false,
      btnNum:0,
      btnRefund:false,
      canRefund:true,
      snowId:'1042313',
    }
  },
  created(){
    localStorage.setItem('snowId',this.snowId)
  },
  mounted() {
    let _t = this;
    _t.chooseOrderType = Number(_t.$route.query.orderType) || Number(sessionStorage.getItem('orderType')) || 0;
    if(_t.$global.token){
      _t.params.token = _t.$global.token;
      _t.stateInfo.token = _t.$global.token;
      _t.loadOrderList()
    }else{
      let interval = setInterval(function () {
        if(_t.$global.token){
          _t.params.token = _t.$global.token;
          _t.stateInfo.token = _t.$global.token;
          clearInterval(interval);
          interval = null;
          _t.loadOrderList()
        }
      },500)
    }
  },
  computed: {
  },
  methods: {
    changeType(type){
      if(type == 4){
        window.location.href  = 'http://m.ciotour.com/ticket/#/orderList'
      }else if(type != 2){
        sessionStorage.setItem('orderType',type)
      }else{
        this.chooseOrderType = 1;
        let url = this.$global.baseMallUrl+this.$global.token+'&redirectUrl=%2fmember%2forder%2flist';
        window.location.href = url;
      }
    },
    //订单状态文字
    getStatusText(row){
      let statusArr = [
        {name:'待付款',color:'green',num:0,btnStatus:false},//1
        {name:'待使用',color:'orange',num:1,btnStatus:false},//2
        {name:'已使用',color:'green',num:2,btnStatus:false},//3
        {name:'已取消',color:'dark',num:3,btnStatus:false},//4
        {name:'已支付',color:'dark',num:4,btnStatus:false},//5
        {name:'出票中',color:'dark',num:5,btnStatus:false},//6
        {name:'出票失败',color:'dark',num:6,btnStatus:false},//7
        {name:'退款审核中',color:'dark',num:7,btnStatus:false},//8
        {name:'退票中',color:'dark',num:8,btnStatus:false},//9
        {name:'退票失败',color:'dark',num:9,btnStatus:false},//10
        {name:'退款中',color:'dark',num:10,btnStatus:false},//11
        {name:'退款失败',color:'dark',num:11,btnStatus:false},//12
        {name:'退款成功',color:'dark',num:12,btnStatus:false},//13
        {name:'财务审核中',color:'dark',num:13,btnStatus:false},//14
        {name:'审核失败',color:'dark',num:14,btnStatus:false},//15
      ];
      if(row.order.orderType == 4){
        statusArr = [
          {name:'待付款',color:'green',num:0,btnStatus:false},//1
          {name:'预订成功',color:'orange',num:1,btnStatus:false},//2
          {name:'已使用',color:'green',num:2,btnStatus:false},//3
          {name:'已取消',color:'dark',num:3,btnStatus:false},//4
          {name:'已支付',color:'dark',num:4,btnStatus:false},//5
          {name:'订单审核中',color:'dark',num:5,btnStatus:false},//6
          {name:'已取消',color:'dark',num:6,btnStatus:false},//7
          {name:'退款审核中',color:'dark',num:7,btnStatus:false},//8
          {name:'退票中',color:'dark',num:8,btnStatus:false},//9
          {name:'退票失败',color:'dark',num:9,btnStatus:false},//10
          {name:'退款中',color:'dark',num:10,btnStatus:false},//11
          {name:'退款失败',color:'dark',num:11,btnStatus:false},//12
          {name:'退款成功',color:'dark',num:12,btnStatus:false},//13
          {name:'财务审核中',color:'dark',num:13,btnStatus:false},//14
          {name:'审核失败',color:'dark',num:14,btnStatus:false},//15
        ]
      }
      let num = 0;
      if(row.orderDetails.length>0){
        if(row.orderDetails[0].refundStatus == null){
          if(row.orderDetails[0].orderStatus == 0 || row.orderDetails[0].orderStatus == 1){
            num = 0
          }
          if(row.orderDetails[0].orderStatus == 7 && (row.orderDetails[0].orderDetailStatus == 4)){
            num = 2
          }
          if(row.order.orderStatus == 2){
            num = 3
          }
          if(row.orderDetails[0].orderStatus == 4){
            num = 4
          }
          if(row.orderDetails[0].orderStatus == 5){
            num = 5
          }
        }else{
          if(row.orderDetails[0].orderRefundStatus == 1){
            num = 7
          }
          if(row.orderDetails[0].orderRefundStatus == 2){
            num = 8
          }
          if(row.orderDetails[0].orderRefundStatus == 3){
            num = 9
          }
          if(row.orderDetails[0].orderRefundStatus == 4){
            num = 10
          }
          if(row.orderDetails[0].orderRefundStatus == 5){
            num = 11
          }
          if(row.orderDetails[0].orderRefundStatus == 6){
            num = 12
          }
          if(row.orderDetails[0].orderRefundStatus == 7){
            num = 13
          }
          if(row.orderDetails[0].orderRefundStatus == 8){
            num = 14
          }

        }
        if(row.orderDetails[0].orderStatus == 6){
          num = 6
        }
        row.orderDetails.map((item,index) => {
          //有一张已使用就是已使用
          if(item.orderStatus == 7 && (item.orderDetailStatus == 4)){
            num = 2
          }
        });
        row.orderDetails.map((item,index) => {
          //已使用和退款失败的状态
          if(item.orderStatus == 7 && item.orderDetailStatus == 4){
            row.orderDetails.map((it,idx) => {
              if(it.refundStatus != null && it.orderRefundStatus == 5){
                num = 1
              }
            });
          }
        });
        let btnStatus = 0;
        row.orderDetails.map((item,index) => {
          //有一张待使用就是待使用
          if(item.orderStatus == 7 && (item.orderDetailStatus == 0 || item.orderDetailStatus == 1 || item.orderDetailStatus == 3)){
            num = 1;
            btnStatus++
          }
        });
        if(btnStatus == row.orderDetails.length){
          if (row.order.orderType == 4 && num == 5){
            row.btnRefund = true;
          } else{
            row.btnRefund = true;
          }
        }
        return statusArr[num]
      }
    },
    //切换tab
    switchState(row,index){
      let _t = this;
      _t.acIdx = index;
      sessionStorage.setItem('ticketListTab',index);
      _t.finished = false;
      _t.params.pageIndex = 1;
      _t.orderlist = [];
      _t.loadOrderList()

    },
    // 申请退款
    refundPay(row){
      let me = this;
      if(!me.canRefund) return;
      me.canRefund = false;
      let s = row.orderDetails[0].sellerId;
      let url = s == localStorage.getItem('snowId') ? me.api.refundSnow : me.api.refund;
      Dialog.confirm({
        cancelButtonText: '我再想想',
        message: '确定申请退款吗？',
      }).then(() => {
        me.$post({
          url:url,
          params: {
            orderNumber: row.order.orderNumber,
            token:me.$global.token,
          }
        }).then(res => {
          Toast('退款成功');
          setTimeout(() => {
            me.orderlist = [];
            me.loadOrderList();
            me.canRefund = true;
          },1500)
        }).catch(err => {
          me.canRefund = true;
          Toast(err.message);
        });
      })
        .catch(() =>{
        });
    },
    // 去支付
    gotoPay(row){
      this.$router.push({
        path:'/payWay',
        query:{
          price:row.orderDetails[0].sellPrice,
          buyNum:row.orderDetails.length,
          code:row.order.orderNumber,
        }
      });
    },
    //再次预定
    gotoTicketsDetail(row){

      this.$router.push({
        // path:'/tiketDetails/' + row.orderDetails[0].sellerId,
        path:'/',
        query:{
          priceCalendarCount:1
        }
      });
    },
    //取消订单
    cancelOrder(row){
      let me = this;
      Dialog.confirm({
        title: '提示',
        message: '确认取消订单？',
      }).then(() => {
        // on confirm
        me.$get({
          url:me.api.cancelOrder,
          params: {
            orderId: row.order.orderId,
            token:me.$global.token,
          }
        }).then(res => {
          Toast('取消成功');
          me.orderlist = [];
          me.loadOrderList()
        });
      })
        .catch(() => {
          // on cancel
        });

    },
    // 添加评论
    addOther() { //添加评论
      this.insertUserCriticism(1)
    },
    insertUserCriticism(num){
      let me = this;
      if (!me.stateInfo.data.content) {
        Toast('请输入评论内容');
        return;
      }
      me.$post({
        url:me.api.insertUserCriticism,
        params: me.stateInfo
      }).then(res => {
        if(num === 1){
          this.stateInfo.data.bizId = this.stateInfoCId;
          this.stateInfo = JSON.parse(JSON.stringify(this.stateInfo));
          this.insertUserCriticism(2);
        }else{
          this.orderlist = [];
          me.loadOrderList();
          Toast('评论成功');
          me.showInp = false;
        }
      });
    },
    // 立即评价
    otherState(row) {
      this.stateInfo.data.bizId = row.orderDetails[0].sellerId;
      this.stateInfoCId = row.orderDetails[0].orderId;
      this.showInp = true
    },
    goToDetail(item) {
      let path = '';
      if(item.orderDetails[0].sellerId == localStorage.getItem('snowId')){
        path = '/orderDetailSnow'
      }else{
        path = '/orderDetail'
      }
      this.$router.push({
        path: path,
        query: {
          orderNumber: item.order.orderNumber
        }
      })
    },
    goToRefund(id) {
      this.$router.push({
        path: '/refund',
        query: {
          orderNumber: id
        }
      })
    },
    exit() {
      sessionStorage.clear();
      localStorage.clear();
      if(this.$global.modelState){
        window.android.exit();
      }else {
        this.$iosMethod(function(bridge) {
          bridge.callHandler('exit', null,function(data) {
          })
        })
      }
    },
    loadOrderList() {
      let me = this;
      this.isLoading = true;
      me.params.orderStatus = me.tabs[me.acIdx].state;
      me.$get({
        url: me.api.orderList,
        params: me.params
      }).then(res => {
        console.log(res)
        for(let i in res.data){
          let totalDiscountAmount = 0
          for(let m in res.data[i].orderDetails){
            totalDiscountAmount += res.data[i].orderDetails[m].totalDiscountAmount
          }
          res.data[i].order['totalDiscountAmount'] = totalDiscountAmount
        }
        this.isLoading = false;
        if (me.refreshing) {
          me.refreshing = false;
        }

        if (res.count) {
          me.orderlist = me.orderlist.concat(res.data);
          me.orderlist.forEach((ele, index) => {
            me.orderlist[index] = JSON.parse(JSON.stringify(Object.assign({}, ele, { color: null })))
          });
        }
        console.log(me.orderlist)
        let list = res.data;
        if (!list || list.length < me.params.pageSize) {
          me.finished = true;
        }
      })

    },

    onRefresh() { //下拉刷新
      let me = this;
      me.orderlist = [];
      me.finished = false;
      me.params.pageIndex = 1;
      me.loadOrderList();
    },
    onLoad() { //上拉加载
      let me = this;
      let page = me.params.pageIndex;
      page = page ? page + 1 : 1;
      me.params.pageIndex = page;
      me.loadOrderList();

    }
  }
}
