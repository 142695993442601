<template>
  <div class="full d_f ali_c j_c p_f">
    <div class="rr_out" v-if="isShowBack">
      <div class="head d_f ali_c t_a">
        <img class="cur_p" src="../hotel/img/close_sp.png" alt="" @click="close">
        <span class="flex_1">退款原因</span>
      </div>
      <div class="r_content">
        <div class="items d_f ali_c" :class="acNum === index ? 'ac' : ''" @click="changeCause(item,index)" v-for="(item,index) in reasonArr" :key="index">
          <span class="flex_1">{{item.name}}</span>
          <img src="../hotel/img/rr_choose.png" alt="" class="cur_p chooseS">
          <img src="../hotel/img/p_uncheck.png" alt="" class="cur_p unChooseS">
        </div>
        <div class="items d_f ali_c" :class="acNum === -1 ? 'ac' : ''" @click="acNum = -1">
          <span class="flex_1">其他原因</span>
          <img src="../hotel/img/rr_choose.png" alt="" class="cur_p chooseS">
          <img src="../hotel/img/p_uncheck.png" alt="" class="cur_p unChooseS">
        </div>
        <div v-if="acNum == -1">
          <input type="text" v-model="causeInput">
        </div>
      </div>
      <div class="btn t_a cur_p" @click="sure">确认提交</div>
    </div>
  </div>
</template>

<script>
  import apis from '../hotel/utils/apis'
  import { Dialog,Toast, CountDown } from 'vant';

  export default {
    name: "refundReason",
    components: {
      'van-dialog':Dialog
    },
    props:{
      orderNumber:{
        type: String,
        default(){
          return ''
        }
      },
      hotelPhone:{
        type: String,
        default(){
          return ''
        }
      },
    },
    data() {
      return {
        isFail:false,
        isShowBack:true,
        reasonArr:[],
        acNum:null,
        cause:'',
        causeInput:'',
      }
    },
    mounted(){
      // this.showDia()
      this.enumsList('compose_return_reason',this.delReason);
    },
    methods: {
      changeCause(row,index){
        this.acNum = index;
        this.cause = row.name;
      },
      // 退款原因枚举
      delReason(info){
        this.reasonArr = JSON.parse(JSON.stringify(info));
      },
      enumsList(key,callback){
        this.$get({
          url: apis.enumsList,
          params: {
            key:key
          }
        }).then(rsp => {
          callback(rsp)
        });
      },
      cancel(){
        this.confirm();
        const link = document.createElement('a');
        link.setAttribute('href', 'tel:'+ this.hotelPhone);
        const evt = document.createEvent('MouseEvents');
        evt.initMouseEvent('click', true, true);
        link.dispatchEvent(evt)
      },
      confirm(){
        this.isFail = false;
        this.isShowBack = true;
        this.close();
      },
      // 确认提交
      sure(){
        let _t = this;
        if(!this.cause && !this.causeInput) return Toast('请选择退款原因！');
        let reason = _t.acNum == -1 ?_t.causeInput : _t.cause;
        _t.$post({
          url:apis.refundApplyCompose,
          params: {
            orderNumber: _t.orderNumber,
            token:_t.$global.token,
            cause: reason,
          }
        }).then(res => {
          if(res.code == 200){
            Toast('退款申请成功');
            setTimeout(function () {
              _t.$emit('close');
            },2000)
          }else{
            this.isFail = true;
            this.showDia()
            this.isShowBack = false;
          }
        });
      },
      showDia(){
        Dialog.confirm({
          showCancelButton:true,
          confirmButtonText:'好的，我知道了',
          cancelButtonText:'联系客服',
          // allowHtml:true,
          message: '退款申请失败',
          // message: '<div class="t_a rr_dia"><img src="../img/rr_fail.png" /><div>退款申请失败</div></div>',
        })
          .then(() => {
            // on confirm
            this.confirm()
          })
          .catch(() => {
            // on cancel
            this.cancel()
          });
      },
      //关闭按钮
      close(){
        this.$emit('close')
      }
    }
  }
</script>

<style scoped lang="scss">
  input{
    width: 100%;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    outline: none;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size:13px;
    font-weight:500;
    color:rgba(62,62,62,1);
  }
  .rr_out{
    .btn{
      height:48px;
      background:linear-gradient(75deg,rgba(127,220,79,1),rgba(29,223,80,1));
      border-radius:0px 0px 3px 3px;
      line-height: 48px;
      font-size:17px;
      font-weight:500;
      color:rgba(255,255,255,1);
    }
    .r_content{
      .items{
        img{
          width: 15px;
          position: relative;
          top: 2px;
        }
        .chooseS{
          display: none;
        }
        .unChooseS{
          display: block;
        }
        &.ac{
          .chooseS{
            display: block;
          }
          .unChooseS{
            display: none;
          }
        }
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
        margin-bottom: 25px;
      }
      padding: 15px 21px;
    }
    .head{
      img{
        width: 14px;
      }
      height: 54px;
      font-size:15px;
      font-weight:500;
      color:rgba(62,62,62,1);
      padding: 0 35px 0 21px;
      border-bottom: 1px solid #D0D0D0;
    }
    width: 333px;
    border-radius: 3px;
    background-color: white;
  }
  .full{
    background-color: rgba(0,0,0,0.51);
    left: 0;
    top: 0;
    z-index: 99;
  }
</style>
